/* 추가적인 CSS 스타일링 - MyComponentStyles.css */

.app {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 20px;
  text-align: center;
  max-width: 100%;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .app {
    padding: 10px;
  }

  .question-text {
    font-size: 18px;
  }

  .score-button {
    padding: 12px 20px;
    font-size: 14px;
  }

  .result-button button {
    padding: 12px 20px;
    font-size: 14px;
  }
}
